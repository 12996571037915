import { Injectable } from '@angular/core';

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log("ErrorInterceptor")
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          // console.log('401');

          // auto logout if 401 response returned from api
          this.authService.logout();
          // location.reload(true);
          location.assign('login');
        }

        // const error = err.error.message || err.statusText;
        return throwError(err);
      })
    );
  }
}
