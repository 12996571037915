import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpBackend } from '@angular/common/http';
import { Session } from '../models/session';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private http: HttpClient;

  constructor(
    handler: HttpBackend,
    private router: Router
  ) {
    this.http = new HttpClient(handler);
  }

  getSession() {
    return JSON.parse(sessionStorage.getItem('session'));
  }

  login(username: string, password: string) {
    return this.http.post<Session>(environment.api_url + '/sessions', {
      username: username,
      password: password,
      type_id: 2
    });
  }

  logout() {
    sessionStorage.clear();
    // this.router.navigate(['/login']);
    window.location.href = '/login'
  }
}
