import { Injectable } from '@angular/core';
import { Role } from '../models/role';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  ADMIN_ID = 1;
  GENERAL_ID = 2;
  DOCTOR_ID = 3;
  GUEST_ID = 4;
  ADMIN_SHOP_ID = 5;
  KIOSK_SHOP_ID = 6;

  roles = [
    { id: this.ADMIN_ID, name: 'Administrator' } as Role,
    // { id: this.GENERAL_ID, name: 'General' } as Role,
    // { id: this.DOCTOR_ID, name: 'Doctor' } as Role,
    // { id: this.GUEST_ID, name: 'Guest' } as Role,
    { id: this.ADMIN_SHOP_ID, name: 'Admin Shop' } as Role,
    { id: this.KIOSK_SHOP_ID, name: 'Kiosk Shop' } as Role
  ];

  constructor() {}
}
