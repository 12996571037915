import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = null;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private roleService: RoleService
  ) {}

  get f() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    this.error = null;
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService
      .login(
        this.loginForm.controls.username.value,
        this.loginForm.controls.password.value
      )
      .subscribe(
        data => {
          this.loading = false;
          this.submitted = false;

          if (data.errors) {
            this.error = data.errors;
          } else {
            // console.log(data);
            sessionStorage.setItem('session', JSON.stringify(data));
            if (data.user.role_id == this.roleService.ADMIN_SHOP_ID) {
              this.router.navigate(['/main/shops']);
            } else {
              this.router.navigate(['/main/foot-measurements']);
            }
          }
        },
        error => {
          this.loading = false;
          this.submitted = false;

          this.error = error.errors;
          // console.log(error);
        }
      );
  }
}
