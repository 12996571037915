import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { ModalModule } from 'ngx-bootstrap';
import { AuthenInterceptor } from './services/authen.interceotor';
import { ErrorInterceptor } from './services/error.interceotor';
import { TabsModule } from 'ngx-bootstrap/tabs';
// import { DragDropDirective } from 'src/app/components/drag-drop/drag-drop.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale } from 'ngx-bootstrap/locale';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PolicyComponent } from './policy/policy.component';
defineLocale('th', deLocale);
@NgModule({
  declarations: [AppComponent, LoginComponent, PolicyComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    // ModalModule.forRoot(),
    TabsModule.forRoot(),
    NgbModule,
    BsDatepickerModule.forRoot(),
    ProgressbarModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
